import styled from 'styled-components';
import { colorsV2 } from '../colors-v2';
import { shadows } from '../shadows';

const textColorFromBgColorMapped = {
  white: colorsV2.black100,
  [colorsV2.white100]: colorsV2.black100,
  black: colorsV2.white100,
  [colorsV2.black100]: colorsV2.white100,
};

export const TooltipMain = styled.div<{ $color?: string; $maxWidth?: string; $zIndex?: number }>`
  z-index: ${(p) => p.$zIndex || 1030};
  max-width: ${(p) => p.$maxWidth || '240px'};
  top: 0;
  left: 0;
  background: ${(p) => p.$color || colorsV2.black100};
  color: ${(p) => textColorFromBgColorMapped[p.$color || colorsV2.black100] || colorsV2.black100};
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5715;
  border: 1px solid transparent;
  box-shadow: ${shadows.zIndex4};

  &.word-break {
    word-break: break-all;
  }

  &.yellow-box {
    color: ${colorsV2.black100};
    background-color: ${colorsV2.yellow40};
  }
  &.mini {
    .popover-arrow {
      display: none;
    }
    .popover-inner-content {
      padding: 4px 6px;
    }
  }
  &.un-styled {
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    border: 0;
    .popover-title {
      border-bottom: none;
    }
  }
  &.arrow {
    border: 1px solid ${colorsV2.gray20};
  }
  &.no-padding {
    padding: 0;
  }
  &.no-border {
    border: 0;
  }
  &.no-arrow {
    .popover-arrow {
      display: none;
    }

    .popover-title {
      padding: 0;
    }
  }
  &.empty-box {
    .popover-inner {
      background-color: transparent;
      border-radius: 0;
    }
    .popover-inner-content {
      display: none;
    }
  }
  &.bordered {
    border-color: ${colorsV2.black20};
  }
  &.custom-inner-content {
    .popover-inner-content {
      padding: 0;
    }
  }
  &.dol-popover {
    padding: 0;
    .popover-inner-content {
      padding: 0;
    }
    .popover-arrow {
      display: none;
    }
    .popover-inner {
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 8px;
      box-shadow: ${shadows.zIndex4};
    }
  }
  &.dark {
    background-color: #242938;
    .popover-arrow {
      background-color: #242938;
    }
  }

  &.no-max-width {
    max-width: unset;
  }
`;

const PLACEMENT_BOTTOM_ARROW_ROTATE_DEGREE = 45;
const getRotateDegreeFromPlacement = (placement = '') => {
  const [position] = placement.split('-');
  switch (position) {
    case 'right':
      return PLACEMENT_BOTTOM_ARROW_ROTATE_DEGREE - 90;
    case 'left':
      return PLACEMENT_BOTTOM_ARROW_ROTATE_DEGREE + 90;
    case 'bottom':
      return PLACEMENT_BOTTOM_ARROW_ROTATE_DEGREE;
    case 'top':
      return PLACEMENT_BOTTOM_ARROW_ROTATE_DEGREE - 180;
  }

  return PLACEMENT_BOTTOM_ARROW_ROTATE_DEGREE;
};

export const TooltipArrowMain = styled.div<{ $color?: string; $placement?: string }>`
  color: transparent;
  position: absolute;
  width: 10px;
  height: 10px;
  background: ${(p) => p.$color || colorsV2.black100};
  border-top: inherit;
  border-left: inherit;
  transform: rotate(${(p) => getRotateDegreeFromPlacement(p.$placement)}deg);
`;
